import React from 'react'
import { Link, navigate } from 'gatsby'
import Maytronics from '../svg/maytronics'
import NewsSidebar from '../sidebar'
import {
	MainSection,
	StoriesSidebarContainer,
	StoriesContainer,
	Main,
	MainTextContainer,
	MainImageContainer,
	StoryCategory,
	MainTitle,
	DateTime,
	Excerpt,
	Continue,
	IndividualContainer,
	StoryContainer,
	StoryTextContainer,
	StoryImageContainer,
	StoryImage,
	StoryTextDetails,
} from './styles'

const MainContent = ({ stories, locale, checkedLocale }) => {
	const [main, ...rest] = stories
	const { category, slug, seoTitle, seoDescription, image, dateAndTime } = main
	return (
		<MainSection>
			<StoriesSidebarContainer>
				<StoriesContainer>
					<Main>
						<MainImageContainer
							onClick={() => {
								navigate(`${checkedLocale}/${slug}`)
							}}
						>
							{image ? (
								<img
									src={image.file.url}
									alt={image.description}
									loading="eager"
								/>
							) : (
								<Maytronics width="100%" height="250" />
							)}
						</MainImageContainer>
						<MainTextContainer>
							<StoryCategory>{category}</StoryCategory>
							<MainTitle>{seoTitle}</MainTitle>
							<DateTime>{dateAndTime}</DateTime>
							<Excerpt>{seoDescription}</Excerpt>
							<Link to={`${checkedLocale}/${slug}/`}>
								<Continue>Continue Reading</Continue>
							</Link>
						</MainTextContainer>
					</Main>

					<IndividualContainer>
						{rest.map((story, i) => {
							return (
								<StoryContainer key={i}>
									<StoryImageContainer>
										{story?.image?.file?.url ?
											<StoryImage
												src={story?.image?.file?.url}
												onClick={() => {
													navigate(`/${story.slug}/`)
												}}
											/>
											: <Maytronics width="100%" />
										}
									</StoryImageContainer>
									<StoryTextContainer>
										<StoryTextDetails>
											<StoryCategory>{story.category}</StoryCategory>
											<MainTitle>{story.seoTitle}</MainTitle>
											<DateTime>{story.dateAndTime}</DateTime>
											<Excerpt>{story.seoDescription}</Excerpt>
										</StoryTextDetails>
										<Link to={`${checkedLocale}/${story.slug}/`}>
											<Continue>Continue Reading</Continue>
										</Link>
									</StoryTextContainer>
								</StoryContainer>
							)
						})}
					</IndividualContainer>
				</StoriesContainer>
				<NewsSidebar categoryLocale={locale} checkedLocale={checkedLocale} />
			</StoriesSidebarContainer>
		</MainSection>
	)
}

export default MainContent
