import React from 'react'
import Maytronics from '../svg/maytronics'
import {
	PopularSection,
	SectionTitle,
	PostsContainer,
	PostsInner,
	DetailedPost,
	DetailedCategory,
	DetailedTtl,
	DetailedDesc,
	DateTime,
	ShortPost,
	ShortPostImageContainer,
	ShortPostDetails,
	ShortPostTtl,
	ShortPostCat,
	ShortPostImage,
} from './styles'

const PopularPosts = ({ popularPosts, checkedLocale }) => {
	return (
		<PopularSection>
			<SectionTitle>Popular Posts</SectionTitle>
			<PostsContainer>
				{popularPosts.map((post, i) => {
					return i === 0 || i === 4 ? (
						<PostsInner width="true" key={i}>
							<DetailedPost to={`${checkedLocale}/${post.slug}/`}>
								<DetailedCategory>{post.category}</DetailedCategory>
								<DetailedTtl>{post.seoTitle}</DetailedTtl>
								<DateTime>{post.dateAndTime}</DateTime>
							</DetailedPost>
						</PostsInner>
					) : (
						<PostsInner key={i}>
							<ShortPost to={`${checkedLocale}/${post.slug}/`}>
								{post.image ? (
									<ShortPostImageContainer>
										<ShortPostImage
											src={
												post.image.file.url
											}
										></ShortPostImage>
									</ShortPostImageContainer>
								) : (
									<ShortPostImageContainer>
										<Maytronics width="100%" height="100" />
									</ShortPostImageContainer>
								)}
								<ShortPostDetails>
									<ShortPostCat>{post.category}</ShortPostCat>
									<ShortPostTtl>{post.seoTitle}</ShortPostTtl>
									<DateTime>{post.dateAndTime}</DateTime>
								</ShortPostDetails>
							</ShortPost>
						</PostsInner>
					)
				})}
			</PostsContainer>
		</PopularSection>
	)
}
export default PopularPosts
