import React from 'react'
import { Link } from 'gatsby'
import {
	StoriesSection,
	Story,
	StoriesCategory,
	StoriesTitle,
} from './styles'

const Stories = ({ stories, checkedLocale }) => {
	return (
		<StoriesSection>
			{stories.map((story, i) => {
				const { category, slug, seoTitle } = story
				return (
					<Link key={i} to={`${checkedLocale}/${slug}/`}>
						<Story>
							<StoriesCategory>{category}</StoriesCategory>
							<StoriesTitle>{seoTitle}</StoriesTitle>
						</Story>
					</Link>
				)
			})}
		</StoriesSection>
	)
}

export default Stories
