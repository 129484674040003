import React from 'react'
import Button from '../utils/button'
import {
	HeroContainer,
	ImageContainer,
	TextContainer,
	Category,
	Title,
	Description,
} from './styles'

const Hero = ({ title, image, description, cta, category }) => {
	return (
		<HeroContainer>
			<ImageContainer
				src={image + '?w=1270&fit=fill'}
			></ImageContainer>
			<TextContainer>
				<Category>{category}</Category>
				<Title>{title}</Title>
				<Description>{description}</Description>
				{cta.map((btn, i) => {
					return <Button {...btn} key={i} sitePadding="2.5rem"></Button>
				})}
			</TextContainer>
		</HeroContainer>
	)
}

export default Hero
